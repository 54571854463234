.main-content {
  // padding-top: map-get($top-bar-height, mobile);
  // @include media-breakpoint-up(lg) {
  //   padding-top: map-get($top-bar-height, desktop);
  // }
}

.actions {
  margin: 1rem 0;
  text-align: center;
  border-radius: 0;
  button {
    //margin-right: 0.7rem;
    border-radius: 0px;
	  background-color: $color-green;
	  border-color: $color-green;
    color: white;
    font-weight: 600;
    padding: 14px 30px;
    line-height: 1;
    letter-spacing: 1px;
    font-size: 18px;

	&:hover{
      background-color: $color-dark-green;
      border-color: $color-dark-green;
    }
  }
}