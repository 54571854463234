@import './mixins';
@import './variables';

// plugins
@import './bootstrap/bootstrap';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
//@import '~react-day-picker/lib/style.css';
@import '~react-modal-video/scss/modal-video.scss';

//@import './fonts';
@import './base';
@import './sections';
@import './form';

// top
//@import './layout/hamburger';
//@import './layout/top';
@import './layout/top-navbar';

// bottom
@import './layout/bottomPanel';
@import './layout/bottomMenu';

@import './cms/cms-site';

// modules
@import './modules/breadcrumb';
@import './modules/rotatingBanner';
@import './modules/bannerSize';
@import './modules/pageTiles';
//@import './modules/blogTiles';
@import './modules/slider';
@import './modules/multiColumnPanel';

// components
@import '../components';

@import './site/index';

//aos
@import "~aos/src/sass/aos";

@media print {
  @import './print';
}

//banner-text-bar
section.banner-text-bar{
  .htmlPanel{
    p{
      margin-bottom: 0px;
      text-align: center;
    }
  }
}

.choose-file-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  label.choose-file-label{
    background-color: white;
    margin-bottom: 0px !important;
    //font-family: $family-button;
    padding: 0.3rem 0.5rem;
    border-radius: 0.25rem;
    font-weight: 600;
    //color: #444;
  }
  div.file__attach__text{
    margin-left: 1rem;
    //color: white;
  }
}

body:not(.cmsedit) {
  div.cms_item.desktop-ig-feed {
    display: none;

    @include media-breakpoint-up(sm) {
      display: block;
    }
  }

  div.cms_item.mobile-ig-feed {
    display: block;

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
}

.grecaptcha-badge { 
  visibility: hidden;
}

.btn{
  border-radius: 0;
  color: white;
}

.in_common_footer{
  iframe {
    box-shadow: 2px 2px 6px 0px rgba(0,0,0,0.25);
  }
}

.remove__button button{
  color: black;
}

.search-result.productList{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: auto;
}

a:hover {
  color: #989898;
}

// change the products colour font to black
.pageTile__big__wrapper .pageTile__big__middle__text {
  color: #212121;
}
// increase the Category left and right margin
@media (min-width: 1600px) {
  .container .pageTiles__big {
    margin-left: -100px;
    margin-right: -100px;
  }
}
@media (min-width: 2500px) {
  .container .pageTiles__big {
    margin-left: -300px;
    margin-right: -300px;
  }
}
.container .pageTiles__big {
  justify-content: center;
}
@media (min-width: 992px) {
  .pageTiles__big .pageTile__big  {
    flex: 1 0 22%;
    max-width: 22%;
  }
}
