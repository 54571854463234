.banner-item {
  max-width: 100vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;

  // &.bannerHasTitlePanel::before {
  //   content: '';
  //   background: rgba(0,0,0,0.3333);
  //   width: 100%;
  //   height: 100%;
  //   position: absolute;
  //   z-index: 5;
  // }

}

.banner__item__container{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  color: white;
  width: 80%;
  text-align: center;
  @include media-breakpoint-up(md){
    //left: 15%;
    //transform: translateY(-50%);
    //width: auto;
  }

}

.banner__item__small__caption__top{
  font-size: 0.9rem;
  @include media-breakpoint-up(md){
    font-size: 1.1rem;
  }
}

.banner__item__big__caption__middle{
  font-size: 1.5rem;
  margin-top: 0.5rem;
  line-height: 1;
  @include media-breakpoint-up(md){
    font-size: 2.5rem;
  }
}

.banner__item__small__caption__bottom{
  max-width: 1000px;
  font-size: 0.8rem;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  @include media-breakpoint-up(md){
    font-size: 0.9rem;
  }
}

.banner__item__caption__button{
  margin-top: 1.3rem;
  button {
    font-weight: 600;
    padding: 10px 30px;
    font-size: 15px;
    line-height: 1;
    letter-spacing: 1px;
    @include media-breakpoint-up(md){
      padding: 14px 30px;
      font-size: 18px;
    }
  }
}

.banner__item__link{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 11;
  &:hover{
    // .banner__item__caption__button button{
    //   background-color: red;
    // }
  }
}

// .banner_item__text {
//   text-align: center;
//   padding: 0.5rem 0 0.7rem;
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   width: 80%;
//   max-width: 1200px;
//   z-index: 10;
//   font-family: $family-base;
//   font-weight: 600;
//   color: #fff;
//   font-size: 1.6rem; 
//   line-height: 1.1;
  
//   @include media-breakpoint-up(lg) {
//     font-size: 2.3rem;
//     margin-bottom: -0.3rem;
//   }
// }

// .banner-item__link {
//   font-size: 1rem; 
//   font-weight: 400;
//   display: inline-block;
//   padding: 0.4rem 1rem;
//   background-color: $primary-color;
//   color: #fff;
//   margin-top: 1rem;

//   @include media-breakpoint-up(lg) {
//   }

//   &:hover, &:focus {
//     color: #fff; 
//     background-color: $link-hover-color;
//     text-decoration: none;
//   }
// }

// .bannerItem__overlay {
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   z-index: 1;
// }

