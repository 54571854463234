.col-20p {
  flex: 0 0 20%;
  max-width: 20%;
}

@include media-breakpoint-up(sm) {
  .col-sm-20p {
    flex: 0 0 20%;
    max-width: 20%;
  }   
}

//line up
.multiColumnPanel{
  max-width: 960px;
  margin: auto;
  .container{
    margin-left: auto;
    margin-right: auto;
    @include media-breakpoint-up(xl){
      margin-left: -15px;
      margin-right: -15px;
    }
  }
}

section.multiColumnPanel.in_common_footer{
  max-width: unset;
  div.container{
    margin-left: auto;
    margin-right: auto;

  }
}

.multiColumnPanel.two__button__style__mutil__column{ 
  @include media-breakpoint-down(md){
    h5{
      text-align: center !important;
    }

    .col-12:nth-child(2) {
      margin-top: 2.5rem;
    }
  }
}